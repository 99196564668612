<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <h5 class="bt">
                        <img src="../assets/images/equip_01.png" class="ico" alt="" />点位管理
                    </h5>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <el-form ref="form" class="top-form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item>
                                    <el-select v-model="form.equimentId" placeholder="选择设备"
                                        @change="getMeters(form.equimentId)">
                                        <el-option v-for="item in equiments" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item>
                                    <el-select v-model="form.merterId" placeholder="选择表">
                                        <el-option v-for="item in meters" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <div class="flex flex-pack-justify">
                                    <button class="ann annbg1" @click="getPointList(form.equimentId)">
                                        查询
                                    </button>
                                    <button class="ann annbg1 addbtn" @click="onAdd()">
                                        添加数据点
                                    </button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" width="60" label="ID">
                            </el-table-column>
                            <el-table-column prop="name" label="名称"> </el-table-column>
                            <el-table-column prop="des" label="描述"> </el-table-column>
                            <el-table-column prop="pointType" label="点位类型">
                            </el-table-column>
                            <el-table-column prop="isVirtual" label="虚拟点" :formatter="formatFreeShippingInfo" width="100">
                            </el-table-column>
                            <el-table-column prop="unit" label="单位"> </el-table-column>
                            <el-table-column prop="max" label="上限"> </el-table-column>

                            <el-table-column prop="min" label="下限"> </el-table-column>
                            <el-table-column prop="scale" label="比例系数"> </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-blue" @click="handleEdit(scope.$index, scope.row)">修改</span>
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 添加点位 -->
        <el-dialog :visible.sync="addPop" width="40%">
            <div class="pop">
                <div class="toptt">
                    <span class="backbtn">添加点位</span>
                    <i class="el-icon-close close" @click="addPop = false"></i>
                    <div class="dix"></div>
                </div>
                <div class="detail">
                    <el-form ref="form" :model="addForm" label-width="95px">
                        <el-row :gutter="10">
                            <el-col :span="16">
                                <el-form-item label="名称:">
                                    <el-input v-model="addForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="8">
                                <el-form-item label="虚拟点:">
                                    <el-switch v-model="addForm.switch" active-color="#9443de" inactive-color="#ddd">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="点位类型:">
                                    <el-select v-model="addForm.type" placeholder="请选择点位类型">
                                        <el-option label="ENERGY_VALUE" value="ENERGY_VALUE"></el-option>
                                        <el-option label="ANALOG_VALUE" value="ANALOG_VALUE"></el-option>
                                        <el-option label="DIGITAL_VALUE" value="DIGITAL_VALUE"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="10">
                                <el-form-item label="单位:">
                                    <el-input v-model="addForm.danwei"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="比例系数:">
                                    <el-input v-model="addForm.xishu"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="10">
                                <el-form-item label="上限:">
                                    <el-input v-model="addForm.shangxian"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="下限:">
                                    <el-input v-model="addForm.xiaxian"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="描述:">
                                    <el-input v-model="addForm.miaoshu"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="btngroup">
                            <button class="ann confirm" @click="onSubmit">确定</button>
                            <button class="ann back" @click="addPop = false">返回</button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { pointList, delPoint, addPoint, editPoint } from "@/api/point";
import { getEquipList, getMeterList } from "@/utils/common";
export default {
    data() {
        return {
            editId: -1,
            form: {
                equimentId: "",
                merterId: "",
            },
            equiments: [],
            meters: [],
            tableData: [],
            pageInfo: {
                total: 0,
                pageSize: 10,
                pageNum: 1,
            },
            loading: false,
            addPop: false,
            addForm: {
                name: "",
                switch: true,
                type: "",
                danwei: "",
                xishu: "",
                shangxian: "",
                xiaxian: "",
                miaoshu: "",
            },
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.equiments = getEquipList();
    },
    mounted() { },
    watch: {},
    methods: {
        //信息转换为是否
        formatFreeShippingInfo(row, column) {
            return row.isVirtual == "1"
                ? "是"
                : row.isVirtual == "0"
                    ? "否"
                    : row.isVirtual;
        },
        handleEdit(index, row) {
            (this.addForm = {
                name: "",
                switch: false,
                type: "",
                danwei: "",
                xishu: "",
                shangxian: "",
                xiaxian: "",
                miaoshu: "",
            }),
                (this.addForm.name = row.name);
            row.isVirtual == "1"
                ? (this.addForm.switch = true)
                : (this.addForm.switch = false);
            this.addForm.miaoshu = row.des;
            this.addForm.shangxian = row.max;
            this.addForm.xiaxian = row.min;
            this.addForm.danwei = row.unit;
            this.addForm.xishu = row.scale;
            this.addForm.type = row.pointType;
            this.editId = row.id;
            this.addPop = true;
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getPointList();
        },
        handleDelete(index, row) {
            this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.deletePoint(row.id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        //获取设备列表
        getMeters(equimentId) {
            this.meters = getMeterList(equimentId);
        },
        //获取数据点列表
        getPointList() {
            this.loading = true
            let param = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                gaugesId: this.form.merterId,
                deviceId: this.form.equimentId,
            };
            if (this.form.equimentId == "-1") {
                delete param.deviceId;
            }
            if (this.form.merterId == "-1") {
                delete param.gaugesId;
            }
            pointList(param).then((res) => {
                if (res.data.code == 0) {
                    this.pageInfo.total = res.data.total;
                    this.tableData = res.data.rows;
                } else {
                    this.$message.warning(res.data.msg);
                }
                this.loading = false
            });
        },
        //删除具体数据点
        deletePoint(id) {
            let param = {
                ids: String(id),
            };
            delPoint(param).then((res) => {
                if (res.data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                } else {
                    this.$message.warning(res.data.msg);
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                }
            });
        },
        //添加设备弹窗
        onAdd() {
            this.editId = -1;
            (this.addForm = {
                name: "",
                switch: true,
                type: "",
                danwei: "",
                xishu: "",
                shangxian: "",
                xiaxian: "",
                miaoshu: "",
            }),
                (this.addPop = true);
        },
        //添加/修改设备
        onSubmit() {
            if (this.addForm.name == "") {
                this.$message.warning("请填写数据点名");
            } else if (this.addForm.type == "") {
                this.$message.warning("请选择点位类型");
            } else if (this.addForm.miaoshu == "") {
                this.$message.warning("请填写点位描述");
            } else {
                let param = {
                    isVirtual: Number(this.addForm.switch),
                    name: this.addForm.name,
                    unit: this.addForm.danwei,
                    scale: this.addForm.xishu,
                    des: this.addForm.miaoshu,
                    max: this.addForm.shangxian,
                    min: this.addForm.xiaxian,
                    pointType: this.addForm.type,
                };
                console.log(param);
                if (this.editId == -1) {
                    addPoint(param).then((res) => {
                        if (res.data.code != 0) {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.$message.warning(res.data.msg);
                            this.addPop = false;
                            this.getPointList();
                        }
                    });
                } else {
                    param.id = this.editId;
                    editPoint(param).then((res) => {
                        if (res.data.code != 0) {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.addPop = false;
                            this.getPointList();
                        }
                    });
                }
            }
        },
    },
};
</script>

<style scoped></style>
